import React, { useEffect, useState, useContext } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useIntl } from "react-intl";
import { Grid, CircularProgress, Snackbar, Alert, AlertTitle, Autocomplete, MenuItem, InputLabel } from "@mui/material";
import { Formik, Form, Field } from "formik";
import ReactGA from "react-ga4";
import useAnalyticsEventTracker from "utils/useAnalyticsEventTracker";
import getEstimatorTemplate from "services/getEstimatorTemplate";
import addEstimation from "services/addEstimation";
import updateEstimation from "services/updateEstimation";
import getEstimation from "services/getEstimation";
import getProduct from "services/getProduct";
import useUser from "hooks/useUser";
import useStaticData from "hooks/useStaticData";
import GlobalContext from "contexts/GlobalContext";
import {
  WrapperControlsLeftStyled,
  TextFieldStyled,
  SelectStyled,
  FormControlStyled,
  WrapperControlsRightStyled,
  GroupHeader,
  GroupItems,
} from "pages/Estimator/style";
import { MapToQuestionValues, MapToNewInitialValues, MapToUpdateInitialValues } from "pages/Estimator/helper";
import ButtonContained from "components/ButtonContained";
import InputDollarParity from "components/InputDollarParity";
import AddEstimation from "components/AddEstimation";
import History from "components/History";
import ExportPdf from "components/ExportPdf";
import Login from "components/Login";
import DotsMenu from "components/DotsMenu";
import GroupSteps from "components/GroupSteps";
import Section from "components/Section";
import CountrySelect from "components/CountrySelect";
import useLang from "hooks/useLang";

export default function Estimator() {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const gaEventTracker = useAnalyticsEventTracker("user_action");
  const translate = useIntl();
  const { getCountries, locale } = useLang();
  const { getTransportTypes } = useStaticData();
  const {
    updateMode,
    setUpdateMode,
    estimation,
    setEstimation,
    isDuplicate,
    setIsDuplicate,
    resetToNew,
    setResetToNew,
    setCompletedGroups,
    setNextActiveStep,
    setActiveStep,
    toUpdateMode,
    setToUpdateMode,
    setExportToPdf,
    exportToPdf,
    setReadyToExportPdf,
  } = useContext(GlobalContext);
  const [estimationTemplate, setEstimationTemplate] = useState([]);
  const [isValueLoded, setIsValueLoaded] = useState(false);
  const [initialFormStateValue, setInitialFormStateValue] = useState({});
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [openSnackAddedSuccess, setOpenSnackAddedSuccess] = useState(false);
  const [openSnackUpdateSuccess, setOpenSnackUpdateSuccess] = useState(false);
  const { isLogged, user } = useUser();

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [openAddModal, setOpenAddModal] = useState(false);

  const [articleValue, setArticleValue] = useState("");

  useEffect(() => {
    document.title = translate.formatMessage({ id: "html.title" });

    if (!isLogged) {
      ReactGA.set({ userId: "Visitante" });
    } else {
      ReactGA.set({ userId: user.email });
    }

    getEstimatorTemplate().then((result) => {
      setEstimationTemplate(result);
      const questionValues = MapToQuestionValues(result);
      setTotalQuestions(questionValues.length);
      const newInitialValue = MapToNewInitialValues(questionValues);
      setInitialFormStateValue(newInitialValue);
      setIsValueLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (resetToNew === true) {
      getEstimatorTemplate().then((result) => {
        setEstimationTemplate(result);
        const questionValues = MapToQuestionValues(result);
        setTotalQuestions(questionValues.length);
        const newInitialValue = MapToNewInitialValues(questionValues);
        setInitialFormStateValue(newInitialValue);
        setArticleValue("");
        setIsValueLoaded(true);
        setResetToNew(false);
        setCompletedGroups([]);
        setNextActiveStep(1);
        setActiveStep(1);
      });
    }
  }, [resetToNew]);

  useEffect(() => {
    if (toUpdateMode === true) {
      getEstimatorTemplate().then((result) => {
        setEstimationTemplate(result);
        const questionValues = MapToQuestionValues(result);
        setTotalQuestions(questionValues.length);
        const newInitialValue = MapToUpdateInitialValues(questionValues, estimation, getCountries());
        setInitialFormStateValue(newInitialValue);
        setArticleValue(newInitialValue.article);
        setIsValueLoaded(true);
        const groupsCompleted = result.groups
          .map((group) => [
            group.id,
            group.sections.flatMap((section) =>
              section.subsections.flatMap((subsection) =>
                subsection.questions
                  .filter((question) => !question.isTotal && !question.isPercentage)
                  .map((question) => question.id)
              )
            ),
          ])
          .map(([groupId, questionIds]) => {
            const hasData = estimation.estimateDetails.some(
              ({ questionId, dollarValue, localValue }) =>
                questionIds.includes(questionId) && (dollarValue !== 0 || localValue !== 0)
            );
            return { groupId, hasData };
          })
          .filter((x) => x.hasData)
          .map((x) => x.groupId);

        setCompletedGroups(groupsCompleted);
        setNextActiveStep(groupsCompleted.length + 1);
        setActiveStep(1);
      });
      setToUpdateMode(false);
    }
  }, [toUpdateMode]);

  useEffect(() => {
    if (isDuplicate === true) {
      getEstimatorTemplate().then((result) => {
        setEstimationTemplate(result);
        const questionValues = MapToQuestionValues(result);
        setTotalQuestions(questionValues.length);
        const newInitialValue = MapToUpdateInitialValues(questionValues, estimation, getCountries());
        setInitialFormStateValue(newInitialValue);
        setIsValueLoaded(true);
        setIsDuplicate(false);
        setUpdateMode(false);
        const groupsCompleted = result.groups
          .map((group) => [
            group.id,
            group.sections.flatMap((section) =>
              section.subsections.flatMap((subsection) =>
                subsection.questions
                  .filter((question) => !question.isTotal && !question.isPercentage)
                  .map((question) => question.id)
              )
            ),
          ])
          .map(([groupId, questionIds]) => {
            const hasData = estimation.estimateDetails.some(
              ({ questionId, dollarValue, localValue }) =>
                questionIds.includes(questionId) && (dollarValue !== 0 || localValue !== 0)
            );
            return { groupId, hasData };
          })
          .filter((x) => x.hasData)
          .map((x) => x.groupId);

        setCompletedGroups(groupsCompleted);
        setNextActiveStep(groupsCompleted.length + 1);
        setActiveStep(1);
      });
    }
  }, [isDuplicate]);

  return !isValueLoded ? (
    <CircularProgress />
  ) : (
    <Grid container direction="column" justifyContent="space-evenly" alignItems="center">
      <Snackbar open={openSnackAddedSuccess} autoHideDuration={4000} onClose={() => setOpenSnackAddedSuccess(false)}>
        <Alert
          elevation={6}
          severity="success"
          variant="filled"
          onClose={() => setOpenSnackAddedSuccess(false)}
          sx={{ width: "100%" }}
        >
          <AlertTitle> {translate.formatMessage({ id: "message.addSuccess" })}</AlertTitle>
        </Alert>
      </Snackbar>
      <Snackbar open={openSnackUpdateSuccess} autoHideDuration={4000} onClose={() => setOpenSnackUpdateSuccess(false)}>
        <Alert
          elevation={6}
          severity="success"
          variant="filled"
          onClose={() => setOpenSnackUpdateSuccess(false)}
          sx={{ width: "100%" }}
        >
          <AlertTitle> {translate.formatMessage({ id: "message.editSuccess" })}</AlertTitle>
        </Alert>
      </Snackbar>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialFormStateValue}
        enableReinitialize
        onSubmit={(values, actions) => {
          debugger;
          let estimate = {
            name: values.name,
            originCity: values.originCity,
            originCountry: values.originCountry?.code ?? "",
            destinationCity: values.destinationCity,
            destinationCountry: values.destinationCountry?.code ?? "",
            product: values.article ?? "",
            isManualProductLoading: values.isManualProductLoading ?? false,
            transportTypeCode: values.transportTypeCode === "0" ? "" : values.transportTypeCode,
            dollarParity: values.dollarParity,
            estimateDetails: [],
          };

          if (updateMode) estimate.id = values.id;

          for (let i = 0; i < values.totalQuestions; i++) {
            if (values[`qv_dollarValue_${i}`] !== 0 || values[`qv_localValue_${i}`] !== 0) {
              let estimateItem = {
                dollarValue: values[`qv_dollarValue_${i}`],
                localValue: values[`qv_localValue_${i}`],
                questionId: values[[`qv_questionId_${i}`]],
                dollarValueFilled: values[`qv_isChangeDollarValue_${i}`],
                percentageValue: values[`qv_percentageValue_${i}`],
                estimateId: values.id,
              };
              estimate.estimateDetails.push(estimateItem);
            }
          }

          if (!updateMode) {
            addEstimation(user.token, estimate).then((id) => {
              gaEventTracker("estimation_add", " estimation add");
              getEstimation(user.token, id).then((est) => {
                setEstimation(est);
                setOpenAddModal(false);
                setOpenSnackAddedSuccess(true);
                setIsDuplicate(false);
                setResetToNew(false);
                setUpdateMode(true);
                setToUpdateMode(true);
              });
            });
          } else {
            updateEstimation(user.token, estimate).then((id) => {
              getEstimation(user.token, id).then((est) => {
                gaEventTracker("estimation_edit", " estimation edit");
                setEstimation(est);
                setOpenSnackUpdateSuccess(true);
                setIsDuplicate(false);
                setResetToNew(false);
                setUpdateMode(true);
                setToUpdateMode(true);
                if (exportToPdf) {
                  setExportToPdf(false);
                  setReadyToExportPdf(true);
                }
              });
            });
          }
        }}
      >
        {(formikProps) => (
          <>
            <Grid container columnSpacing={{ xs: 0, sm: 2 }} style={{ marginTop: matchesSM ? "0" : "35px" }}>
              <Grid item xs={12} sm="auto">
                <History />
              </Grid>
              <Grid item xs={12} sm="auto">
                <ExportPdf />
              </Grid>
              <Grid item xs={12} sm="auto">
                {isLogged && updateMode && (
                  <ButtonContained
                    text={translate.formatMessage({ id: "button.new" })}
                    onClick={() => {
                      if (updateMode) setUpdateMode(false);
                      if (estimation !== null) setEstimation(null);
                      if (resetToNew) setResetToNew(false);
                      setResetToNew(true);
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <Form autoComplete="off" style={{ width: "100%" }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ marginBottom: "50px" }}
              >
                {updateMode && <Field as="input" type="hidden" name="id" />}
                {isLogged && updateMode && (
                  <Grid item xs={12} sm="12" style={{ textAlign: "right", marginTop: matchesSM ? "15px" : "0" }}>
                    <DotsMenu />
                  </Grid>
                )}
                <Grid
                  container
                  columnSpacing={{ xs: 0, sm: 2 }}
                  style={{ marginTop: matchesSM || updateMode ? "0" : "35px" }}
                >
                  <Grid item xs={12} sm="6" md="auto">
                    <InputDollarParity rowsCount={totalQuestions} />
                  </Grid>
                  <Grid item xs={12} sm="6" md="auto">
                    <CountrySelect
                      labelTitle={translate.formatMessage({ id: "input.originCountry" })}
                      componentName="originCountry"
                      fieldNameValue="originCountry"
                      fieldNames={["originCountry", "addOriginCountry"]}
                    />
                  </Grid>
                  <Grid item xs={12} sm="6" md="auto">
                    <Field
                      as={TextFieldStyled}
                      label={translate.formatMessage({ id: "input.originCity" })}
                      variant="outlined"
                      name="originCity"
                      onChange={(option) => {
                        formikProps.setFieldValue(`originCity`, option.target.value);
                        formikProps.setFieldValue(`addOriginCity`, option.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm="6" md="auto">
                    <CountrySelect
                      labelTitle={translate.formatMessage({ id: "input.destinationCountry" })}
                      componentName="destinationCountry"
                      fieldNameValue="destinationCountry"
                      fieldNames={["destinationCountry", "addDestinationCountry"]}
                    />
                  </Grid>
                  <Grid item xs={12} sm="6" md="auto">
                    <Field
                      as={TextFieldStyled}
                      label={translate.formatMessage({ id: "input.destinationCity" })}
                      variant="outlined"
                      name="destinationCity"
                      onChange={(option) => {
                        formikProps.setFieldValue(`destinationCity`, option.target.value);
                        formikProps.setFieldValue(`addDestinationCity`, option.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container columnSpacing={{ xs: 0, sm: 2 }} style={{ marginTop: matchesSM ? "0" : "35px" }}>
                  <Grid item xs={12} sm="6" md="auto">
                    <Autocomplete
                      freeSolo
                      options={options}
                      groupBy={(option) => option.parent}
                      inputValue={articleValue}
                      renderInput={(params) => (
                        <TextFieldStyled
                          {...params}
                          freeSolo
                          label={translate.formatMessage({ id: "input.article" })}
                          variant="outlined"
                          placeholder={translate.formatMessage({ id: "placeholder.addArticle" })}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading && <CircularProgress color="inherit" size={20} />}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      onInputChange={(e) => {
                        if (e !== null && e.target.value !== 0) {
                          if (e.target.value.length >= 3) {
                            setLoading(true);
                            getProduct(e.target.value, locale, 0, 1000).then((productsList) => {
                              let products = productsList.map((p) => ({
                                product: p.name, // `${p.parent}- ${p.name}`,
                                parent: p.parent,
                              }));
                              setOptions(products);
                              setLoading(false);
                            });
                            formikProps.setFieldValue(`article`, e.target.value);
                            formikProps.setFieldValue(`addArticle`, e.target.value);
                            formikProps.setFieldValue(`isManualProductLoading`, true);
                            formikProps.setFieldValue(`addIsManualProductLoading`, true);
                          }
                          setArticleValue(e.target.value);
                        }
                      }}
                      onChange={(event, newValue) => {
                        if (newValue.product) {
                          formikProps.setFieldValue(`article`, newValue.product);
                          formikProps.setFieldValue(`addArticle`, newValue.product);
                          formikProps.setFieldValue(`isManualProductLoading`, false);
                          formikProps.setFieldValue(`addIsManualProductLoading`, false);
                          setArticleValue(newValue.product);
                        }
                      }}
                      getOptionLabel={(option) => option.product}
                      filterOptions={(x) => x}
                      loading={loading}
                    />
                  </Grid>
                  <Grid item xs={12} sm="6" md="auto">
                    <FormControlStyled fullWidth>
                      <InputLabel id="transport-type-label">
                        {translate.formatMessage({ id: "input.transportType" })}
                      </InputLabel>
                      <SelectStyled
                        labelId="transport-type-label"
                        label={translate.formatMessage({ id: "input.transportType" })}
                        value={formikProps.values.transportTypeCode}
                        onChange={(option) => {
                          formikProps.setFieldValue(`transportTypeCode`, option.target.value);
                          formikProps.setFieldValue(`addTransportTypeCode`, option.target.value);
                        }}
                      >
                        {getTransportTypes().map((tt) => (
                          <MenuItem value={tt.code}>{tt.description}</MenuItem>
                        ))}
                      </SelectStyled>
                    </FormControlStyled>
                  </Grid>
                  {updateMode && (
                    <Grid item xs={12} sm="6" md="auto">
                      <Field
                        as={TextFieldStyled}
                        label={translate.formatMessage({ id: "input.name" })}
                        variant="outlined"
                        name="name"
                        onChange={(option) => {
                          formikProps.setFieldValue(`name`, option.target.value);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <GroupSteps groups={estimationTemplate.groups}>
                {(sections) => sections.map((section) => <Section section={section} />)}
              </GroupSteps>
              <Grid container justifyContent="center">
                <Grid xs={12} md={10} lg={10}>
                  <WrapperControlsRightStyled>
                    {isLogged && !updateMode && (
                      <AddEstimation openAddModal={openAddModal} setOpenAddModal={setOpenAddModal} />
                    )}
                    {updateMode && (
                      <ButtonContained
                        componentwidth="416px"
                        text={translate.formatMessage({ id: "button.save" })}
                        onClick={() => formikProps.submitForm()}
                      />
                    )}
                    <Login />
                  </WrapperControlsRightStyled>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </Grid>
  );
}
