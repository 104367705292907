export const MapToQuestionValues = (estimationTemplate) => {
  let indexTotSubsection = 0;
  let indexFirstQuestSubsection = 0;

  const questionValues = estimationTemplate.groups
    .flatMap((group, groupIndex) => {
      const dependentGroup = estimationTemplate.groups.find((g) => g.dependecyGroupId === group.id);
      return group.sections.flatMap((section, sectionIndex) =>
        section.subsections.flatMap((subsection, subsectionIndex) => {
          if (groupIndex === 0 && sectionIndex === 0 && subsectionIndex === 0) {
            indexFirstQuestSubsection = 0;
            indexTotSubsection = subsection.questions.length - 1;
          } else {
            indexFirstQuestSubsection = indexTotSubsection + 1;
            indexTotSubsection = indexFirstQuestSubsection + subsection.questions.length - 1;
          }
          return subsection.questions.map((question) => ({
            groupId: group.id,
            sectionId: section.id,
            subsectionId: subsection.id,
            questionId: question.id,
            isPercentage: question.isPercentage,
            maxPercentage: question.maxPercentage,
            isTotal: question.isTotal,
            indexFirstQuestionSubsection: indexFirstQuestSubsection,
            indexTotalSubsection: indexTotSubsection,
            subsectionOperation: question.subsectionOperations
              ? {
                  subsectionId: question.subsectionOperations.id,
                  operationType: question.subsectionOperations.operationType,
                }
              : null,
            subsectionIndexRelated: [],
            dependentGroupId: dependentGroup ? dependentGroup.id : null,
          }));
        })
      );
    })
    .map((item, index) => ({
      index,
      ...item,
    }));

  for (let i = 0; i < questionValues.length; i++) {
    if (questionValues[i].subsectionOperation != null) {
      for (let x = 0; x < questionValues.length; x++) {
        if (questionValues[x].subsectionId === questionValues[i].subsectionOperation.subsectionId) {
          questionValues[x].subsectionIndexRelated.push(questionValues[i].index);
        }
      }
    }
  }
  return questionValues;
};
export const MapToNewInitialValues = (questionValues) => ({
  name: "",
  dollarParity: 0,

  originCountry: null,
  originCity: "",
  destinationCountry: null,
  destinationCity: "",
  article: "",
  isManualProductLoading: false,
  transportTypeCode: "0",

  addOriginCountry: null,
  addOriginCity: "",
  addDestinationCountry: null,
  addDestinationCity: "",
  addArticle: "",
  addIsManualProductLoading: false,
  addTransportTypeCode: "0",

  totalQuestions: questionValues.length,
  ...questionValues.reduce(
    (accum, current, index) =>
      Object.assign(accum, {
        [`qv_groupId_${index}`]: current.groupId,
        [`qv_sectionId_${index}`]: current.sectionId,
        [`qv_questionId_${index}`]: current.questionId,
        [`qv_dollarValue_${index}`]: 0,
        [`qv_localValue_${index}`]: 0,
        [`qv_isChangeDollarValue_${index}`]: false,
        [`qv_isChangeLocalValue_${index}`]: false,
        [`qv_isPercentage_${index}`]: current.isPercentage,
        [`qv_percentageValue_${index}`]: 0,
        [`qv_maxPercentage_${index}`]: current.maxPercentage,
        [`qv_colorChangeDollarValue_${index}`]: "#000000d9",
        [`qv_colorChangeLocalValue_${index}`]: "#000000d9",
        [`qv_indexFirstQuestionSubsection_${index}`]: current.indexFirstQuestionSubsection,
        [`qv_indexTotalSubsection_${index}`]: current.indexTotalSubsection,
        [`qv_isTotal_${index}`]: current.isTotal,
        [`qv_subsectionOperation_indexTotalSubsection_${index}`]:
          current.subsectionOperation === null
            ? null
            : questionValues.find((x) => x.subsectionId === current.subsectionOperation.subsectionId && x.isTotal)
                .index,
        [`qv_subsectionOperation_operationType_${index}`]:
          current.subsectionOperation === null ? null : current.subsectionOperation.operationType,
        [`qv_subsectionIndexRelated_${index}`]: current.subsectionIndexRelated,
      }),
    {}
  ),
});

export const MapToUpdateInitialValues = (questionValues, estimation, countries) => {
  const oringinCountryObject = countries.find((element) => element.code === estimation.originCountry);
  const destinationCountryObject = countries.find((element) => element.code === estimation.destinationCountry);
  return {
    id: estimation.id ? estimation.id : 0,
    name: estimation.name,

    originCountry: oringinCountryObject,
    originCity: estimation.originCity,
    destinationCountry: destinationCountryObject,
    destinationCity: estimation.destinationCity,
    article: estimation.product,
    transportTypeCode: estimation.transportTypeCode === "" ? "0" : estimation.transportTypeCode,

    addOriginCountry: oringinCountryObject,
    addOriginCity: estimation.originCity,
    addDestinationCountry: destinationCountryObject,
    addDestinationCity: estimation.destinationCity,
    addArticle: estimation.product,
    addTransportTypeCode: estimation.transportTypeCode === "" ? "0" : estimation.transportTypeCode,

    dollarParity: estimation.dollarParity,
    totalQuestions: questionValues.length,
    ...questionValues.reduce((accum, current, index) => {
      const estimationItem = estimation.estimateDetails.find((i) => i.questionId === current.questionId);
      return Object.assign(accum, {
        [`qv_groupId_${index}`]: current.groupId,
        [`qv_sectionId_${index}`]: current.sectionId,
        [`qv_questionId_${index}`]: current.questionId,
        [`qv_dollarValue_${index}`]: estimationItem !== undefined ? estimationItem.dollarValue : 0,
        [`qv_localValue_${index}`]: estimationItem !== undefined ? estimationItem.localValue : 0,
        [`qv_isChangeDollarValue_${index}`]: estimationItem !== undefined ? estimationItem.dollarValueFilled : false,
        [`qv_isChangeLocalValue_${index}`]: estimationItem !== undefined ? !estimationItem.dollarValueFilled : false,
        [`qv_isPercentage_${index}`]: current.isPercentage,
        [`qv_percentageValue_${index}`]: estimationItem !== undefined ? estimationItem.percentageValue : 0,
        [`qv_maxPercentage_${index}`]: current.maxPercentage,
        [`qv_colorChangeDollarValue_${index}`]:
          estimationItem !== undefined && estimationItem.dollarValueFilled ? "#2EAEC1" : "#000000d9",
        [`qv_colorChangeLocalValue_${index}`]:
          estimationItem !== undefined && !estimationItem.dollarValueFilled ? "#2EAEC1" : "#000000d9",
        [`qv_indexFirstQuestionSubsection_${index}`]: current.indexFirstQuestionSubsection,
        [`qv_indexTotalSubsection_${index}`]: current.indexTotalSubsection,
        [`qv_isTotal_${index}`]: current.isTotal,
        [`qv_subsectionOperation_indexTotalSubsection_${index}`]:
          current.subsectionOperation === null
            ? null
            : questionValues.find((x) => x.subsectionId === current.subsectionOperation.subsectionId && x.isTotal)
                .index,
        [`qv_subsectionOperation_operationType_${index}`]:
          current.subsectionOperation === null ? null : current.subsectionOperation.operationType,
        [`qv_subsectionIndexRelated_${index}`]: current.subsectionIndexRelated,
      });
    }, {}),
  };
};
